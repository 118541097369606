import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AlertDialog = (props) => {
  const handleClose = () => {
    props.onAlertClose(props.alertProps.title);
  };

  return (
    <div>
      <Dialog
        open={props.alertProps.display}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {props.alertProps.title}
        </DialogTitle>
        <DialogContent>
          {Array.isArray(props.alertProps.description) ? (
            <DialogContentText id="alert-dialog-description">
              {props.alertProps.description.map((desc) => (
                <p key={desc}>{desc}</p>
              ))}
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              {props.alertProps.description}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
