import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./TabTable.css";

export default function TabTable(props) {
  const tableColumns = props.tableColumns;
  const [tableData, setTableData] = useState(props.tableData);
  const [rowsPerPage] = useState(20);

  const handleChangePage = (_, newPage) => {
    props.onPageChange(newPage);
  };

  useEffect(() => {
    setTableData(props.tableData);
  }, [props]);

  return (
    <div className="wrapper">
      <TableContainer sx={{ maxHeight: 440, minHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="table-container"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                {/* <Checkbox
                  className="mt-5"
                  checked={selectAll}
                  onChange={onChangeSelectAll}
                  indeterminate={isIndeterminate}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                /> */}
              </TableCell>
              {tableColumns.map((column) => (
                <React.Fragment key={column.id}>
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <label className="pb-2 w-100">{column.label}</label>
                    <TextField
                      id="search"
                      label="Search"
                      variant="outlined"
                      size="small"
                      onChange={(ev) => props.search(ev.target.value, "name")}
                      sx={{ width: "40%" }}
                    />
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <>
            {props.isLoading === "true" ? (
              <TableBody>
                <tr>
                  <td colSpan="4">
                    <CircularProgress
                      style={{
                        position: "absolute",
                        margin: "auto",
                        top: "50%",
                        left: "50%",
                      }}
                      children
                    />
                  </td>
                </tr>
              </TableBody>
            ) : (
              <TableBody>
                {tableData.map((row, rowIndex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowIndex}
                    >
                      <TableCell key={rowIndex}>
                        <Checkbox
                          checked={row.checked}
                          onChange={(e) => props.onChangeCheckbox(e, row)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        />
                      </TableCell>
                      {tableColumns.map((column) => {
                        const value = row[column.id];
                        return (
                          <React.Fragment key={column.id}>
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </>
        </Table>
      </TableContainer>
      <div className="pagination-container">
        <div className="selected-count-wrapper">
          Total {props.totalItemCount} | Selected {props.selectedCount}
        </div>
        <div className="pagination-wrapper">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={props.totalItemCount}
            rowsPerPage={rowsPerPage}
            page={props.currentPage}
            showFirstButton
            showLastButton
            onPageChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
