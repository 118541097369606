import { Grid, Switch } from "@mui/material";
import CustomAccordion from "../../components/CustomAccordion/CustomAccordion";
import CustomSwitch from "../../components/CustomSwitch/CustomSwitch";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonService from "../../services/CommonService";
import { formDetailsTab } from "../../reducers/detailsTab";

const BrandsConfiguration = () => {
  const commonService = new CommonService();

  const dispatch = useDispatch();

  const detailsTabSelector = useSelector((state) => state.detailsTab.value);

  const onChange = (inField, value) => {
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    tempFields[inField] = value;
    dispatch(formDetailsTab(tempFields));
  };

  return (
    <CustomAccordion>
      <Grid container gap={5} mt={5}>
        <CustomSwitch
          label="Calculate Free Drivers (Dedicated) ETA"
          id="show_reach_eta"
          value={detailsTabSelector.show_reach_eta}
          onChange={onChange}
        />
        <CustomSwitch
          label="Calculate Assigned Drivers (On Demand) ETA"
          id="show_return_eta"
          value={detailsTabSelector.show_return_eta}
          onChange={onChange}
        />
        <CustomSwitch
          label="Calculate Returning Drivers (Dedicated) ETA"
          id="show_wait_eta"
          value={detailsTabSelector.show_wait_eta}
          onChange={onChange}
        />
      </Grid>
    </CustomAccordion>
  );
};

export default BrandsConfiguration;
