class CommonService {
  constructor() {}

  getDeepCopy(inValue) {
    return JSON.parse(JSON.stringify(inValue));
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (err) => reject(err);
      }
    });
  };

  getImageResolution = async (file) => {
    const res = await this.getBase64(file);
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = res;
      image.onload = () =>
        resolve({ height: image.height, width: image.width });
      image.onerror = (err) => reject(err);
    });
  };

  validateFile = (type, size, resolution) => {
    const res = [];
    if (!type) res.push("File type must be PNG, JPG, JPEG");
    if (size > 5) res.push("File size must not exceed 5MB");
    if (resolution.height < 320 || resolution.width < 320)
      res.push("Image resolution must be 320x320");
    return res;
  };
}

export default CommonService;
