import { Switch, Grid } from "@mui/material";

const CustomSwitch = (props) => {
  const onChange = (e) => {
    props.onChange(props.id, e.target.checked);
  };

  return (
    <Grid item display="flex" justifyContent={"space-between"} width="100%">
      <label htmlFor={props.id}>{props.label}</label>
      <Switch
        id={props.id}
        value={props.value}
        onChange={onChange}
        checked={props.value}
      />
    </Grid>
  );
};

export default CustomSwitch;
