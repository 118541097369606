import { Autocomplete, TextField } from "@mui/material";

const AccountSelector = (props) => {
  return (
    <Autocomplete
      className={props.width ? "mt-3" : ""}
      disablePortal
      options={props.accountsList}
      loading={props.isLoading}
      getOptionLabel={(option) => option.name || ""}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      value={props.value}
      autoComplete
      onChange={props.onAccountSelection}
      size="small"
      sx={{ width: props.width || "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Account"
          error={props.error ? props.error : false}
          helperText={props.error && "This field is required"}
        />
      )}
    />
  );
};

export default AccountSelector;
