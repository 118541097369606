import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const CustomAccordion = (props) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Accordion
      expanded={expanded}
      className="mb-5"
      onChange={() => setExpanded((prev) => !prev)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ background: "#DCDCDC" }}
      >
        <Typography variant="p">ETA</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="p">{props.children}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
