import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./ListTable.css";
import BrandService from "../../services/BrandService";

export default function ListTable(props) {
  const brandService = new BrandService();

  const [selectAll, setSelectAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [selectedListCount, setSelectedListCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataList, setDataList] = useState(props.brandsList);

  const copyDataList = useRef(props.brandsList);
  const selectedList = useRef([]);

  const columns = props.columns;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onChangeSelectAll = (event) => {
    const newArr = copyDataList.current;
    newArr.forEach((row) => {
      row["checked"] = event.target.checked;
    });
    selectedList.current = event.target.checked
      ? newArr.map((el) => el.id)
      : [];
    setSelectAll(event.target.checked);
    setIsIndeterminate(false);
    setSelectedListCount(event.target.checked ? newArr.length : 0);
    setDataList([...newArr]);
    copyDataList.current = newArr;
    const checkedList = copyDataList.current.filter((list) => list.checked);
    props.onChangeOfCheckbox(checkedList);
  };

  const search = (searchString, field) => {
    props.onSearch(searchString, field);
  };

  const onListSelect = (ev, inRow) => {
    if (ev.target.checked) {
      selectedList.current.push(inRow.id);
    } else {
      const index = selectedList.current.indexOf(inRow.id);
      const tempArr = JSON.parse(JSON.stringify(selectedList.current));
      tempArr.splice(index, 1);
      selectedList.current = tempArr;
    }
    const newTempArr = copyDataList.current;
    newTempArr.forEach((row) => {
      if (row.id === inRow.id) {
        row["checked"] = ev.target.checked;
      }
    });
    setDataList([...newTempArr]);
    copyDataList.current = newTempArr;
    checkIfIndeterminate();
    const checkedList = copyDataList.current.filter((list) => list.checked);
    props.onChangeOfCheckbox(checkedList);
  };

  const checkIfIndeterminate = () => {
    const tempSelectedCount = copyDataList.current.filter(
      (brand) => brand["checked"] === true
    ).length;
    const tempTotalCount = copyDataList.current.length;
    if (tempSelectedCount !== 0) {
      setSelectedListCount(tempSelectedCount);
      if (tempSelectedCount === tempTotalCount) {
        setSelectAll(true);
        setIsIndeterminate(false);
      } else {
        setSelectAll(false);
        setIsIndeterminate(true);
      }
    } else {
      setIsIndeterminate(false);
      setSelectAll(false);
      setSelectedListCount(0);
    }
  };

  const getUiElement = (column, value, id) => {
    if (column.id === "name") {
      return (
        <Tooltip title={"Click on the link to view/edit Brand"} arrow>
          <span>
            <a
              href="/"
              onClick={(e) => onEditClick(e, id)}
              className="tab-font-wrap"
              title={value}
            >
              {value}
            </a>
          </span>
        </Tooltip>
      );
    } else {
      return brandService.getUiElement(column, value, id);
    }
  };

  const onEditClick = (e, id) => {
    e.preventDefault();
    props.onEditClick(id);
  };

  const handleChangePage = (event, newPage) => {
    setSelectAll(false);
    setIsIndeterminate(false);
    props.handleChangePage(newPage);
  };

  useEffect(() => {
    copyDataList.current = props.brandsList;
    setDataList(props.brandsList);
  }, [props]);

  return (
    <div className="col-sm-12 wrapper">
      <TableContainer sx={{ maxHeight: "63vh", minHeight: "63vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="poi-list-table"
        >
          <TableHead>
            <TableRow>
              <TableCell className="sticky-checkbox-header">
                <Checkbox
                  className="mt-5"
                  checked={selectAll}
                  onChange={onChangeSelectAll}
                  indeterminate={isIndeterminate}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                />
              </TableCell>
              {columns.map((column) => (
                <React.Fragment key={column.id}>
                  {column.filterType === "search" ? (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.minWidth,
                      }}
                      className={
                        column.id === "name" ? "sticky-header-name" : ""
                      }
                    >
                      <label className="pb-2 w-100">{column.label}</label>
                      <TextField
                        id="search"
                        label={`Search ${column.label}`}
                        variant="outlined"
                        size="small"
                        value={props.selectedFilters[column.id]}
                        onChange={(ev) => search(ev.target.value, column.id)}
                        sx={{ height: 39 }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        maxWidth: column.minWidth,
                      }}
                    >
                      <label className="pb-0">{column.label}</label>
                      <Autocomplete
                        size="small"
                        sx={{ marginTop: "10px", maxWidth: column.minWidth }}
                        multiple={column.multiple}
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={props.dropdownFilters[column.id]}
                        getOptionLabel={(option) => option.name || ""}
                        value={props.selectedFilters[column.id]}
                        onChange={(ev, val) => {
                          if (props.multiple) {
                            props.onFiltersChange(val, column.id);
                          } else {
                            props.onStatusChange(val, column.id);
                          }
                        }}
                        renderOption={(props, option, { selected }) => (
                          <Box {...props} key={option.id}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            <p
                              style={{
                                fontSize: "15px",
                                margin: "0",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {option.name}
                            </p>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={column.label}
                            placeholder="Select"
                          />
                        )}
                      />
                    </TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          {props.isLoading === "true" ? (
            <TableBody>
              <tr>
                <td colSpan="4">
                  <CircularProgress
                    style={{
                      position: "absolute",
                      margin: "auto",
                      top: "50%",
                      left: "50%",
                    }}
                    children
                  />
                </td>
              </tr>
            </TableBody>
          ) : (
            <>
              {props.totalItemCount ? (
                <TableBody>
                  {dataList.map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        <TableCell key={rowIndex} className="sticky">
                          <Checkbox
                            checked={row.checked}
                            onChange={(e) => onListSelect(e, row)}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: 20 },
                            }}
                          />
                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <React.Fragment key={column.id}>
                              <TableCell
                                key={column.id}
                                align={column.align}
                                className={
                                  column.id === "name" ? "sticky-name" : ""
                                }
                              >
                                <>{getUiElement(column, value, row["id"])}</>
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <div
                  className="select-account"
                  style={{
                    display: "block",
                    left: "50%",
                  }}
                >
                  <p>No data in the selected filter</p>
                </div>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <div className="pagination-container">
        <div className="selected-count-wrapper">
          Total {props.totalItemCount} | Selected {selectedListCount}
        </div>
        <div className="pagination-wrapper">
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={props.totalItemCount}
            rowsPerPage={rowsPerPage}
            page={props.currentPage}
            showFirstButton
            showLastButton
            onPageChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
}
