import { useQuery } from "@apollo/client";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EXPORT_BRANDS } from "../../graphql/queries/BrandsListQuery";
import AccountSelector from "../AccountSelector/AccountSelector";
import SettingsMenu from "../SettingsMenu/SettingsMenu";

export default function ActionToolBar(props) {
  const brandsListSelector = useSelector((state) => state.brandsList.value);

  const [accountsList, setAccountsList] = useState([]);

  useEffect(() => {
    setAccountsList(props.accountsList);
  }, [props]);

  const onAccountSelection = (ev, value) => {
    props.onAccountSelection(ev, value);
  };

  const { data, loading, error } = useQuery(EXPORT_BRANDS, {
    variables: {
      clientId:
        brandsListSelector.selectedAccount &&
        brandsListSelector.selectedAccount.id,
    },
    skip:
      brandsListSelector.selectedAccount &&
      !brandsListSelector.selectedAccount.id,
  });

  const exportBrands = () => {
    if (error) {
      props.setAlertProps({
        title: "Error",
        id: "activateError",
        description: "Something went wrong. Please try again",
        display: true,
      });
    }
    if (data) {
      const url = window.URL.createObjectURL(new Blob([data.exportBrands]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        brandsListSelector.selectedAccount.name + " brands list.csv"
      );
      link.click();
    }
  };

  return (
    <div className="row pb-3">
      <div className="d-flex wrapper">
        <div className="col-sm-6 pb-3">
          <AccountSelector
            width={200}
            isLoading={props.isLoading}
            onAccountSelection={onAccountSelection}
            accountsList={accountsList}
          />
        </div>
        <div className="col-sm-6 button-div">
          {props.user.permissions.brand_viewBrands &&
            props.user.permissions.brand_createBrand && (
              <Button
                variant="contained"
                size="small"
                onClick={() => props.onAddClick()}
              >
                Add
              </Button>
            )}
          {brandsListSelector.selectedAccount && (
            <>
              {props.user.permissions.brand_viewBrands &&
                props.user.permissions.brand_editBrand && (
                  <Button
                    variant="contained"
                    size="small"
                    disabled={props.isDisableEdit}
                    onClick={() => props.onEditClick()}
                  >
                    Edit
                  </Button>
                )}
              {props.user.permissions.brand_viewBrands &&
                props.user.permissions.brand_exportBrands && (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => exportBrands()}
                    disabled={loading}
                  >
                    Export
                  </Button>
                )}
              <SettingsMenu
                hideOptions={props.hideOptions}
                isDisabledState={props.isDisableSettings}
                onActivateClick={props.onActivateClick}
                onDeactivateClick={props.onDeactivateClick}
                onDeleteClick={props.onDeleteClick}
                user={props.user}
              ></SettingsMenu>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
