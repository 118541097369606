import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "userInfo",
    initialState: {value: {}},
    reducers: {
        userInfo: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const {userInfo} = userSlice.actions;

export default userSlice.reducer;