import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { KeyboardArrowDown } from "@mui/icons-material";

export default function SettingsMenu(props) {
  const anchorRef = React.useRef(null);
  const [btnOpen, setBtnOpen] = React.useState(false);

  const handleBtnToggle = () => {
    setBtnOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (option) => {
    if (option === "activate") props.onActivateClick();
    if (option === "deactivate") props.onDeactivateClick();
    if (option === "delete") props.onDeleteClick();
    setBtnOpen(false);
  };

  return (
    <>
      {props.user?.permissions?.brand_viewBrands &&
        (props.user?.permissions?.brand_activateBrand ||
          props.user?.permissions?.brand_deleteBrand ||
          props.user?.permissions?.brand_deactivateBrand) && (
          <ButtonGroup
            variant="contained"
            ref={anchorRef}
            sx={{ boxShadow: "none" }}
            disabled={props.isDisabledState}
          >
            <Button
              size="small"
              aria-controls={btnOpen ? "split-button-menu" : undefined}
              aria-expanded={btnOpen ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleBtnToggle}
              endIcon={<KeyboardArrowDown />}
            >
              <SettingsIcon />
            </Button>
          </ButtonGroup>
        )}
      <Popper
        sx={{
          zIndex: 3,
        }}
        open={btnOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.hideOptions.showActive &&
                    props.user?.permissions?.brand_viewBrands &&
                    props.user?.permissions?.brand_activateBrand && (
                      <MenuItem
                        onClick={() => handleClose("activate")}
                        disableRipple
                      >
                        Activate
                      </MenuItem>
                    )}
                  {props.hideOptions.showDeactive &&
                    props.user?.permissions?.brand_viewBrands &&
                    props.user?.permissions?.brand_deactivateBrand && (
                      <MenuItem
                        onClick={() => handleClose("deactivate")}
                        disableRipple
                      >
                        Deactivate
                      </MenuItem>
                    )}
                  {props.user?.permissions?.brand_viewBrands &&
                    props.user?.permissions?.brand_deleteBrand && (
                      <MenuItem
                        onClick={() => handleClose("delete")}
                        disableRipple
                      >
                        Delete
                      </MenuItem>
                    )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
