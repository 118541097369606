import { createSlice } from "@reduxjs/toolkit";

export const brandsListSlice = createSlice({
  name: "brandsList",
  initialState: {
    value: {
      selectedAccount: "",
      viewType: "",
      selectedBrand: "",
      accountsList: [],
      // checkedBrands: [],
    },
    reloadState: { isReload: false },
  },

  reducers: {
    brandsList: (state, action) => {
      state.value = action.payload;
    },
    reloadBrandsList: (state, action) => {
      state.reloadState = action.payload;
    },
  },
});

export const { brandsList, reloadBrandsList } = brandsListSlice.actions;
export default brandsListSlice.reducer;
