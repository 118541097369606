import { gql } from "@apollo/client";

export const UPADTE_BRAND = gql`
  mutation updateBrand(
    $clientId: Int!
    $integrationNumber: String!
    $name: String!
    $show_reach_eta: Int!
    $show_return_eta: Int!
    $show_wait_eta: Int!
    $icon: String
    $pois: [Int]
    $brandId: Int!
  ) {
    updateBrand(
      input: {
        client_id: $clientId
        integration_number: $integrationNumber
        name: $name
        show_reach_eta: $show_reach_eta
        show_return_eta: $show_return_eta
        show_wait_eta: $show_wait_eta
        icon: $icon
        brand_id: $brandId
      }
    ) {
      id
    }
    updateBrandPois(input: { brand_id: $brandId, pois: $pois }) {
      id
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation createBrand(
    $clientId: Int!
    $integrationNumber: String!
    $name: String!
    $show_reach_eta: Int!
    $show_return_eta: Int!
    $show_wait_eta: Int!
    $icon: String
    $pois: [Int]
  ) {
    createBrand(
      input: {
        client_id: $clientId
        integration_number: $integrationNumber
        name: $name
        show_reach_eta: $show_reach_eta
        show_return_eta: $show_return_eta
        show_wait_eta: $show_wait_eta
        icon: $icon
        pois: $pois
      }
    ) {
      success
      data
    }
  }
`;

export const ACTIVATE_BRANDS = gql`
  mutation activateBrands($ids: [Int]!) {
    activateBrands(ids: $ids) {
      success
    }
  }
`;

export const DEACTIVATE_BRANDS = gql`
  mutation deactivateBrands($ids: [Int]!) {
    deactivateBrands(ids: $ids) {
      success
    }
  }
`;

export const DELETE_BRANDS = gql`
  mutation deleteBrands($ids: [Int]!) {
    deleteBrands(ids: $ids) {
      success
    }
  }
`;
