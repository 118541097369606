import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import AssignedPois from "../../pages/AssignedPois/AssignedPois";
import BrandDetails from "../../pages/BrandDetails/BrandDetails";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import SettingsMenu from "../SettingsMenu/SettingsMenu";
import BrandsConfiguration from "../../pages/BrandsConfiguration/BrandsConfiguration";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function TabModal(props) {
  const brandsListSelector = useSelector((state) => state.brandsList.value);
  const detailsTabSelector = useSelector((state) => state.detailsTab.value);

  const hideOptions = useRef({
    showActive: false,
    showDeactive: false,
  });

  useEffect(() => {
    props.tabModalChild.current = props.handleOpenDialog;
  }, []);

  useEffect(() => {
    if (brandsListSelector.viewType === "edit") {
      if (detailsTabSelector.is_active === 1) {
        hideOptions.current = { showActive: false, showDeactive: true };
      } else {
        hideOptions.current = { showActive: true, showDeactive: false };
      }
    } else {
      hideOptions.current = { showActive: false, showDeactive: false };
    }
  }, [detailsTabSelector.is_active]);

  return (
    <>
      <ConfirmationDialog
        confirmProps={props.confirmProps}
        onConfirmClick={props.onConfirmClick}
        onCancelClick={props.onCancelClick}
      />

      <Dialog
        fullScreen
        open={props.isDialogOpen}
        onClose={props.closeDialog}
        sx={{
          position: "absolute",
          maxWidth: "60%",
          left: "40%",
        }}
        TransitionComponent={Transition}
      >
        <>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                component={"div"}
                sx={{ fontSize: "1rem !important", fontWeight: "500" }}
              >
                Add Brand
              </Typography>
              {brandsListSelector.viewType === "edit" && (
                <SettingsMenu
                  hideOptions={hideOptions.current}
                  onActivateClick={props.onActivateClick}
                  onDeactivateClick={props.onDeactivateClick}
                  onDeleteClick={props.onDeleteClick}
                ></SettingsMenu>
              )}
            </Box>
          </DialogTitle>
          <TabContext value={props.tabValue}>
            <Box sx={{ p: 3 }}>
              <TabList onChange={props.onTabChange}>
                <Tab label="Details" value="1" />
                <Tab label="Configuration" value="2" />
                <Tab label="Assigned POIs" value="3" />
              </TabList>
            </Box>
          </TabContext>
          <DialogContent dividers={true}>
            <TabContext value={props.tabValue}>
              <TabPanel value="1" sx={{ padding: 0 }}>
                <BrandDetails
                  errors={props.errors}
                  onAccountSelection={props.onAccountSelection}
                  isLoading={props.accountIsLoading}
                  onActivateClick={props.onActivateClick}
                  onDeactivateClick={props.onDeactivateClick}
                  onDeleteClick={props.onDeleteClick}
                ></BrandDetails>
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "24px 0px 0px" }}>
                <BrandsConfiguration />
              </TabPanel>
              <TabPanel value="3" sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <AssignedPois></AssignedPois>
              </TabPanel>
            </TabContext>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Button variant="outlined" onClick={props.handleClose}>
              Cancel
            </Button>
            {props.user.permissions.brand_viewBrands &&
              ((props.user.permissions.brand_editBrand &&
                brandsListSelector.viewType === "edit") ||
                (props.user.permissions.brand_createBrand &&
                  brandsListSelector.viewType === "add")) && (
                <Button variant="contained" onClick={props.onSubmitClick}>
                  Save
                </Button>
              )}
          </DialogActions>
        </>
      </Dialog>
    </>
  );
}
