import { createSlice } from "@reduxjs/toolkit";

export const detailsTabSlice = createSlice({
  name: "formDetailsTab",
  initialState: {
    value: {
      show_reach_eta: false,
      show_return_eta: false,
      show_wait_eta: false,
      selectedPois: [],
    },
  },
  reducers: {
    formDetailsTab: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { formDetailsTab } = detailsTabSlice.actions;
export default detailsTabSlice.reducer;
