import React, { useEffect, useRef, useState } from "react";
import TabTable from "../../components/TabTable/TabTable";
import CommonService from "../../services/CommonService";
import { useDispatch, useSelector } from "react-redux";
import { PoisService } from "../../services/PoisService";
import { poiAssign } from "../../reducers/assignedPois";
import { formDetailsTab } from "../../reducers/detailsTab";

export default function AssignedPois() {
  const commonService = new CommonService();
  const poisService = new PoisService();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userInfo.value);
  const brandsListSelector = useSelector((state) => state.brandsList.value);
  const detailsTabSelector = useSelector((state) => state.detailsTab.value);

  const tableColumns = [{ id: "poi", label: "Assigned POIs", minWidth: 100 }];

  const [poisList, setPoisList] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [isLoading, setIsLoading] = useState("false");
  const [timerId, setTimerId] = useState(null);
  const [selectedPoiCount, setSelectedPoiCount] = useState(0);

  const selectedFilters = useRef({});
  const currentPage = useRef(0);
  const copyPoisList = useRef([]);
  const selectedPois = useRef([]);

  const getPoisList = async () => {
    setIsLoading("true");
    setPoisList([]);

    const tempGetPois = await poisService.getPoisList(
      user.id,
      currentPage.current,
      selectedFilters.current.name,
      selectedPois.current
    );

    setTotalItemCount(tempGetPois.total);
    copyPoisList.current = tempGetPois.poisList;
    setPoisList(copyPoisList.current);
    dispatch(poiAssign(copyPoisList.current));
    setIsLoading("false");
  };

  const getAssignedPoisList = async () => {
    try {
      setIsLoading("true");
      setPoisList([]);

      const tempGetAssignedPois = await poisService.getAssignedPoisList(
        brandsListSelector.selectedBrand,
        user.id,
        currentPage.current,
        selectedFilters.current.name,
        selectedPois.current
      );

      setTotalItemCount(tempGetAssignedPois.total);
      copyPoisList.current = tempGetAssignedPois.poisList;
      setPoisList(copyPoisList.current);
      setSelectedPoiCount(selectedPois.current.length);
      dispatch(poiAssign(copyPoisList.current));
      setIsLoading("false");
    } catch (err) {
      setPoisList([]);
    }
  };

  const handleChangePage = (newPage) => {
    currentPage.current = newPage;
    brandsListSelector.viewType === "edit"
      ? getAssignedPoisList()
      : getPoisList();
  };

  const onPoiSelect = (ev, inRow) => {
    if (ev.target.checked) {
      selectedPois.current.push(inRow);
    } else {
      // const index = selectedPois.current.indexOf(inRow.id);
      const index = selectedPois.current.map((item, idx) => {
        if (item.id === inRow.id) return idx;
      })[0];
      const tempArr = commonService.getDeepCopy(selectedPois.current);
      tempArr.splice(index, 1);
      selectedPois.current = tempArr;
    }
    const newTempArr = commonService.getDeepCopy(copyPoisList.current);
    newTempArr.forEach((row) => {
      if (row.id === inRow.id) {
        row["checked"] = ev.target.checked;
      }
    });
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    tempFields["selectedPois"] = [...selectedPois.current];
    dispatch(formDetailsTab(tempFields));
    setPoisList([...newTempArr]);
    setSelectedPoiCount(selectedPois.current.length);
    copyPoisList.current = newTempArr;
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;
    setSelectedPoiCount(0);

    clearTimeout(timerId);
    const id = setTimeout(() => {
      brandsListSelector.viewType === "edit"
        ? getAssignedPoisList()
        : getPoisList();
    }, 1000);
    setTimerId(id);
  };

  useEffect(() => {
    brandsListSelector.viewType === "edit"
      ? getAssignedPoisList()
      : getPoisList();
  }, []);

  useEffect(() => {
    selectedPois.current = detailsTabSelector.selectedPois
      ? [...detailsTabSelector.selectedPois]
      : [];
  }, []);

  return (
    <TabTable
      tableColumns={tableColumns}
      tableData={poisList}
      isLoading={isLoading}
      totalItemCount={totalItemCount}
      currentPage={currentPage.current}
      onPageChange={handleChangePage}
      selectedCount={selectedPoiCount}
      onChangeCheckbox={onPoiSelect}
      search={search}
    ></TabTable>
  );
}
