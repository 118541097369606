import { createSlice } from "@reduxjs/toolkit";

export const assignedPoisSlice = createSlice({
  name: "poiAssign",
  initialState: {
    value: [],
  },
  reducers: {
    poiAssign: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { poiAssign } = assignedPoisSlice.actions;
export default assignedPoisSlice.reducer;
