import { useApolloClient } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionToolBar from "../../components/ActionToolBar/ActionToolBar";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import ListTable from "../../components/ListTable/ListTable";
import TabModal from "../../components/TabModal/TabModal";
import {
  EXPORT_BRANDS,
  GET_BRAND,
  GET_BRANDS_LIST,
  GET_FILTERS,
} from "../../graphql/queries/BrandsListQuery";
import { GET_ACCOUNTS, GET_USER } from "../../graphql/queries/CommonQueries";
import { userInfo } from "../../reducers/user";
import BrandService from "../../services/BrandService";
import CommonService from "../../services/CommonService";
import { brandsList as brandsListAction } from "../../reducers/brandsList";
import "./BrandList.css";
import { formDetailsTab } from "../../reducers/detailsTab";
import { useMutation } from "@apollo/client";
import {
  ACTIVATE_BRANDS,
  CREATE_BRAND,
  DEACTIVATE_BRANDS,
  DELETE_BRANDS,
  UPADTE_BRAND,
} from "../../graphql/mutations/BrandsListMutation";
import { Alert, Snackbar } from "@mui/material";

export default function BrandsList() {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const commonService = new CommonService();
  const brandService = new BrandService();

  const brandsListSelector = useSelector((state) => state.brandsList.value);
  const detailsTabSelector = useSelector((state) => state.detailsTab.value);
  const userInfoSelector = useSelector((state) => state.userInfo.value);

  const [isLoading, setIsLoading] = useState("false");
  const [alertProps, setAlertProps] = useState({
    title: "",
    description: "",
    display: false,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accountsList, setAccountsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [checkedList, setCheckedList] = useState([]);
  const [timerId, setTimerId] = useState(null);
  const [dropdownFilters, setDropdownFilters] = useState({
    assigned_pois: [],
    brand_status: [
      { id: 0, name: "Inactive" },
      { id: 1, name: "Active" },
    ],
  });
  const [errors, setErrors] = useState({
    name: false,
    account: false,
  });
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const [tabValue, setTabValue] = useState("1");
  const [confirmProps, setConfirmProps] = useState({
    title: "",
    id: "",
    description: "",
    display: false,
  });

  const [createBrandMutation] = useMutation(CREATE_BRAND, {
    refetchQueries: [EXPORT_BRANDS],
  });
  const [updateBrandMutation] = useMutation(UPADTE_BRAND, {
    refetchQueries: [EXPORT_BRANDS],
  });
  const [activateBrandsMutation] = useMutation(ACTIVATE_BRANDS, {
    refetchQueries: [EXPORT_BRANDS],
  });
  const [deactivateBrandsMutation] = useMutation(DEACTIVATE_BRANDS, {
    refetchQueries: [EXPORT_BRANDS],
  });
  const [deleteBrandsMutation] = useMutation(DELETE_BRANDS, {
    refetchQueries: [EXPORT_BRANDS],
  });

  const copyBrandsList = useRef([]);
  const selectedAccount = useRef({ id: "", name: "" });
  const selectedFilters = useRef({});
  const selectedBrands = useRef([]);
  const currentPage = useRef(0);
  const tabModalChild = useRef(null);
  const hideOptions = useRef({
    showActive: false,
    showDeactive: false,
  });
  const refetchBrandList = useRef(null);

  const getUserData = async () => {
    try {
      setIsLoading("true");
      let userId = Cookies.get("user_id");
      if (userId) {
        const fetchUserData = await client.query({
          query: GET_USER,
          variables: { userId: Number(userId) },
          fetchPolicy: "network-only",
        });
        let userData = commonService.getDeepCopy(
          fetchUserData.data.getUserByID
        );
        userData["permissions"] = JSON.parse(
          fetchUserData.data.getUserByID.permissions
        );
        dispatch(userInfo(userData));
        getAccountsList(userId);
      } else {
        setAlertProps({
          title: "Session Timeout",
          description: "Your session timed out! please login again",
          display: true,
          id: "sessionTimeout",
        });
      }
    } catch (err) {
      // console.log(err.networkError.result, err.networkError.response);
    }
  };

  const getAccountsList = async (inUserId) => {
    try {
      const tempGetAccounts = await client.query({
        query: GET_ACCOUNTS,
        variables: { userId: inUserId },
        fetchPolicy: "network-only",
      });
      const brandsListField = commonService.getDeepCopy(brandsListSelector);
      brandsListField["accountsList"] = tempGetAccounts.data.listAccounts;
      dispatch(brandsListAction(brandsListField));
      setAccountsList(tempGetAccounts.data.listAccounts);
    } catch (err) {
      // console.log(err);
      const brandsListField = commonService.getDeepCopy(brandsListSelector);
      brandsListField["accountsList"] = [];
    } finally {
      setIsLoading("false");
    }
  };

  const onAccountSelection = (ev, value) => {
    selectedBrands.current = [];
    selectedFilters.current = {};
    setCheckedList([]);
    const brandListField = commonService.getDeepCopy(brandsListSelector);
    const detailsTabField = commonService.getDeepCopy(detailsTabSelector);
    if (value) {
      selectedAccount.current = value;
      currentPage.current = 0;
      getBrandsList().then(() => getFilters());
    } else {
      selectedAccount.current = {};
      setFilterApplied(false);
      setTotalItemCount(0);
    }
    brandListField["selectedAccount"] = value;
    detailsTabField["client_id"] = value;
    dispatch(formDetailsTab(detailsTabField));
    dispatch(brandsListAction(brandListField));
  };

  const getBrandsList = async () => {
    try {
      setIsLoading("true");
      setBrandsList([]);
      if (!selectedAccount.current.id) return;
      refetchBrandList.current = null;
      copyBrandsList.current = [];
      const tempGetBrandsList = await client.query({
        query: GET_BRANDS_LIST,
        variables: brandService.prepareVariablesToGetBrands(
          selectedAccount,
          currentPage,
          selectedFilters
        ),
        fetchPolicy: "network-only",
      });
      setTotalItemCount(tempGetBrandsList.data.listBrandsPaginated.total);
      copyBrandsList.current = brandService.prepareBrandsList(
        tempGetBrandsList.data.listBrandsPaginated.data,
        checkedList
      );
      const checkFilterLength = Object.keys(selectedFilters.current).length;
      pageChanged(checkFilterLength ? true : false);
      setBrandsList(copyBrandsList.current);
    } catch (err) {
      // console.log(err);
      setBrandsList([]);
      setTotalItemCount(0);
    } finally {
      setIsLoading("false");
    }
  };

  const onEditClick = (inId) => {
    const user = userInfoSelector;
    if (user.permissions.brand_viewBrands) {
      const brandsListField = commonService.getDeepCopy(brandsListSelector);
      brandsListField["viewType"] = "edit";
      brandsListField["selectedBrand"] = inId;
      dispatch(brandsListAction(brandsListField));
      getBrandDetails(inId);
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "You don't have the permission!",
      });
    }
  };

  const getBrandDetails = async (brandId) => {
    try {
      setIsLoading("true");
      const tempGetBrand = await client.query({
        query: GET_BRAND,
        variables: { brandId: brandId },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });
      if (tempGetBrand.errors) {
        const errMessage = tempGetBrand.errors.map((err) => err.message);
        setSnackBarState({
          ...snackBarState,
          message: errMessage,
          open: true,
        });
        setIsLoading("false");
      } else {
        brandService.initializeGetBrand(tempGetBrand);
        setIsLoading("false");
        tabModalChild.current();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const getFilters = async () => {
    try {
      setIsLoading("true");
      if (!selectedAccount.current.id) return;
      copyBrandsList.current = [];
      const brandsGetFilters = await client.query({
        query: GET_FILTERS,
        variables: { clientId: selectedAccount.current.id },
        fetchPolicy: "network-only",
      });

      const dropFilters = {
        ...dropdownFilters,
        assigned_pois: brandsGetFilters.data.getBrandsPoisFilter,
      };

      setDropdownFilters(dropFilters);
      setIsLoading("false");
    } catch (err) {
      // console.log(err);
    }
  };

  const onFiltersChange = (inFilters, inColumn) => {
    const tempFilterArr = [];
    currentPage.current = 0;
    if (inFilters) {
      inFilters.forEach((src) => {
        tempFilterArr.push(src);
      });
      if (tempFilterArr.length) {
        selectedFilters.current[inColumn] = tempFilterArr;
      } else {
        delete selectedFilters.current[inColumn];
      }
    }
    getBrandsList();
  };

  const onStatusChange = (inFilters, inColumn) => {
    selectedFilters.current[inColumn] = inFilters;
    getBrandsList();
  };

  const pageChanged = (isFilterApplied) => {
    if (isFilterApplied) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  };

  const handleChangePage = (newPage) => {
    setCheckedList([]);
    currentPage.current = newPage;
    getBrandsList();
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;

    clearTimeout(timerId);
    const id = setTimeout(() => getBrandsList(), 1000);
    setTimerId(id);
  };

  const onChangeOfCheckbox = (inCheckedList) => {
    setCheckedList(inCheckedList);
  };

  const checkActivateDeactivateHideState = () => {
    if (checkedList && checkedList.length) {
      const checkForActiveBrand = checkedList.filter(
        (el) => el.is_active === 1
      );
      const checkForInactiveBrand = checkedList.filter(
        (el) => el.is_active !== 1
      );
      if (checkForActiveBrand && checkForActiveBrand.length) {
        hideOptions.current.showDeactive = true;
      } else {
        hideOptions.current.showDeactive = false;
      }
      if (checkForInactiveBrand && checkForInactiveBrand.length) {
        hideOptions.current.showActive = true;
      } else {
        hideOptions.current.showActive = false;
      }
    }
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setConfirmProps({
      title: "Cancel",
      id: "cancel",
      description: "All the changes will be discarded. Do you want to proceed?",
      display: true,
    });
  };

  const onSubmitClick = () => {
    if (
      !detailsTabSelector.name &&
      !(detailsTabSelector.client_id && detailsTabSelector.client_id.id)
    ) {
      setErrors({
        name: true,
        account: true,
      });
      setTabValue("1");
      return;
    }
    if (
      !detailsTabSelector.name ||
      !(detailsTabSelector.client_id && detailsTabSelector.client_id.id)
    ) {
      setErrors({
        name: !detailsTabSelector.name ? true : false,
        account: !(
          detailsTabSelector.client_id && detailsTabSelector.client_id.id
        )
          ? true
          : false,
      });
      setTabValue("1");
      return;
    }
    setErrors({ name: false, account: false });
    setConfirmProps({
      title: "Save",
      id: "save",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };

  const onActivateClick = () => {
    setConfirmProps({
      title: "Activate",
      id: "activate",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };
  const onDeactivateClick = () => {
    setConfirmProps({
      title: "Deactivate",
      id: "deactivate",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };
  const onDeleteClick = () => {
    setConfirmProps({
      title: "Delete",
      id: "delete",
      description: "All the changes will be saved. Do you want to proceed?",
      display: true,
    });
  };

  const handleCloseDialog = () => {
    const brandsListField = commonService.getDeepCopy(brandsListSelector);
    brandsListField["viewType"] = "";
    brandsListField["selectedBrand"] = null;
    dispatch(brandsListAction(brandsListField));
    setTabValue("1");
    dispatch(formDetailsTab({ client_id: brandsListSelector.selectedAccount }));
    setIsDialogOpen(false);
  };

  const onSubmit = () => {
    setAlertProps({ title: "", id: "", description: "", display: false });
    setIsLoading("true");
    if (brandsListSelector.viewType == "add") {
      createBrand();
    } else {
      updateBrand();
    }
  };

  const updateBrand = () => {
    const prepareUpdateBrandData = brandService.prepareBrandMutationData();
    updateBrandMutation({
      variables: prepareUpdateBrandData,
      onCompleted(successResp) {
        if (successResp) {
          setAlertProps({
            title: "Success!",
            id: "saveSuccess",
            description: "Brand has been updated successfully",
            display: true,
          });
          setIsLoading("false");
        } else {
          setAlertProps({
            title: "Error",
            id: "saveError",
            description: successResp.createBrand.data,
            display: true,
          });
          setIsLoading("false");
        }
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors?.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "saveError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const createBrand = () => {
    const prepareCreateBrandData = brandService.prepareBrandMutationData();
    createBrandMutation({
      variables: prepareCreateBrandData,
      onCompleted(successResp) {
        if (successResp.createBrand.success === true) {
          setAlertProps({
            title: "Success!",
            id: "saveSuccess",
            description: "Brand has been created successfully",
            display: true,
          });
          setIsLoading("false");
        } else {
          setAlertProps({
            title: "Error",
            id: "saveError",
            description: successResp.createBrand.data,
            display: true,
          });
          setIsLoading("false");
        }
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "saveError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const activateBrands = (ids) => {
    setIsLoading("true");
    activateBrandsMutation({
      variables: {
        ids,
      },
      onCompleted: (successResponse) => {
        if (successResponse.activateBrands.success === true) {
          setAlertProps({
            title: "Activated",
            id: "activateSuccess",
            description: "The Brand(s) have been activated successfully.",
            display: true,
          });
          hideOptions.current = { showActive: false, showDeactive: true };
        } else {
          setAlertProps({
            title: "Error",
            id: "activateError",
            description: successResponse.activateBrands.data,
            display: true,
          });
        }

        setIsLoading("false");
      },
      onError(errorResponse) {
        const errMessage = errorResponse.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "activateError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const deactiveBrands = (ids) => {
    setIsLoading("true");
    deactivateBrandsMutation({
      variables: {
        ids,
      },
      onCompleted: (successResponse) => {
        if (successResponse.deactivateBrands.success === true) {
          setAlertProps({
            title: "Deactivate",
            id: "deactivateSuccess",
            description: "The Brand(s) have been deactivated successfully.",
            display: true,
          });
          hideOptions.current = { showActive: true, showDeactive: false };
        } else {
          setAlertProps({
            title: "Error",
            id: "deactivateError",
            description: successResponse.deactivateBrands.data,
            display: true,
          });
        }
        setIsLoading("false");
      },
      onError(errorResponse) {
        const errMessage = errorResponse.graphQLErrors?.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "deactivateError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const deleteBrands = (ids) => {
    setIsLoading("true");
    deleteBrandsMutation({
      variables: {
        ids,
      },
      onCompleted: (successResponse) => {
        if (successResponse.deleteBrands.success === true) {
          setAlertProps({
            title: "Delete",
            id: "deleteSuccess",
            description: "The Brand(s) have been deleted successfully.",
            display: true,
          });
        } else {
          setAlertProps({
            title: "Error",
            id: "deleteError",
            description: successResponse.deleteBrands.data,
            display: true,
          });
        }
        setIsLoading("false");
      },
      onError(errorResponse) {
        const errMessage = errorResponse.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          id: "deleteError",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const onCancelClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
  };

  const onConfirmClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
    const ids = checkedList.map((item) => Number(item.id));
    switch (ev.id) {
      case "cancel":
        handleCloseDialog();
        break;
      case "save":
        onSubmit();
        break;
      case "activate":
        activateBrands(ids);
        handleCloseDialog();
        break;
      case "deactivate":
        deactiveBrands(ids);
        handleCloseDialog();
        break;
      case "delete":
        deleteBrands(ids);
        handleCloseDialog();
        setCheckedList([]);
        break;
      default:
        return;
    }
  };

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const closeDialog = () => {};

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    checkActivateDeactivateHideState();
  }, [checkedList]);

  const columns = [
    {
      id: "name",
      label: "Brand",
      minWidth: 170,
      filterType: "search",
    },
    {
      id: "brand_status",
      label: "Brand Status",
      minWidth: 250,
      filterType: "selectWithSearch",
      multiple: true,
    },
    {
      id: "assigned_pois",
      label: "Assigned POIs",
      minWidth: 250,
      filterType: "selectWithSearch",
      multiple: true,
    },
    {
      id: "brand_ref",
      label: "Brand Reference ID",
      minWidth: 250,
      filterType: "search",
    },
  ];

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      message: "",
    });
  };

  const onAddClick = () => {
    tabModalChild.current();
    const brandsListField = commonService.getDeepCopy(brandsListSelector);
    brandsListField["viewType"] = "add";
    dispatch(brandsListAction(brandsListField));
  };

  const onAlertClose = () => {
    setAlertProps({ title: "", description: "", display: false });
    if (alertProps.id === "saveSuccess" || alertProps.id === "deleteSuccess") {
      handleCloseDialog();
    }
    if (alertProps.id === "sessionTimeout") {
      window.location.href = process.env.REACT_APP_BASE_API_URL;
      return;
    }
    getFilters();
    getBrandsList();
  };

  useEffect(() => {
    if (
      userInfoSelector?.permissions &&
      !userInfoSelector?.permissions?.brand_viewBrands
    ) {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "You don't have the permission to access the page!",
      });
    } else {
      setSnackBarState({
        open: false,
        vertical: "top",
        horizontal: "center",
        message: "",
      });
    }
  }, [userInfoSelector]);

  return (
    <div style={{ padding: "1.5rem 1.5rem 0 1.5rem" }}>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarState.vertical,
          horizontal: snackBarState.horizontal,
        }}
        autoHideDuration={6000}
        open={snackBarState.open}
        key={snackBarState.vertical + snackBarState.horizontal}
      >
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: "100%" }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
      {userInfoSelector?.permissions?.brand_viewBrands && (
        <>
          <ActionToolBar
            accountsList={accountsList}
            onAddClick={onAddClick}
            onEditClick={() => onEditClick(checkedList[0].id)}
            onAccountSelection={onAccountSelection}
            hideOptions={hideOptions.current}
            selectedAccount={selectedAccount.current}
            isDisableEdit={checkedList.length === 1 ? false : true}
            isDisableSettings={checkedList.length ? false : true}
            isLoading={isLoading === "true" ? true : false}
            onActivateClick={onActivateClick}
            onDeactivateClick={onDeactivateClick}
            onDeleteClick={onDeleteClick}
            setAlertProps={setAlertProps}
            user={userInfoSelector}
          ></ActionToolBar>
          <AlertDialog alertProps={alertProps} onAlertClose={onAlertClose} />
          <>
            <>
              {selectedAccount.current.name ? (
                <ListTable
                  columns={columns}
                  brandsList={brandsList}
                  dropdownFilters={dropdownFilters}
                  onFiltersChange={onFiltersChange}
                  selectedFilters={selectedFilters.current}
                  totalItemCount={totalItemCount}
                  onSearch={search}
                  handleChangePage={handleChangePage}
                  currentPage={currentPage.current}
                  isLoading={isLoading}
                  onChangeOfCheckbox={onChangeOfCheckbox}
                  setBrandsList={setBrandsList}
                  onEditClick={onEditClick}
                  setCheckedList={setCheckedList}
                  checkedList={checkedList}
                  onStatusChange={onStatusChange}
                ></ListTable>
              ) : (
                <div
                  className="select-account"
                  style={{
                    display:
                      (!selectedAccount.current.name || !totalItemCount) &&
                      !isFilterApplied
                        ? "block"
                        : "none",
                  }}
                >
                  <p>
                    {selectedAccount.current.name && !totalItemCount
                      ? "No data in the selected Account"
                      : "Select an Account to load data"}
                  </p>
                </div>
              )}
            </>
          </>
          <TabModal
            onAccountSelection={onAccountSelection}
            accountIsLoading={isLoading}
            tabModalChild={tabModalChild}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            onConfirmClick={onConfirmClick}
            confirmProps={confirmProps}
            onCancelClick={onCancelClick}
            onSubmitClick={onSubmitClick}
            handleCloseDialog={handleCloseDialog}
            handleClose={handleClose}
            handleOpenDialog={handleOpenDialog}
            onTabChange={onTabChange}
            tabValue={tabValue}
            alertProps={alertProps}
            isDialogOpen={isDialogOpen}
            closeDialog={closeDialog}
            onActivateClick={onActivateClick}
            onDeactivateClick={onDeactivateClick}
            onDeleteClick={onDeleteClick}
            errors={errors}
            user={userInfoSelector}
          ></TabModal>
        </>
      )}
    </div>
  );
}
