import PersonIcon from "@mui/icons-material/Person";
import {
  Box,
  Button,
  Grid,
  Input,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountSelector from "../../components/AccountSelector/AccountSelector";
import { formDetailsTab } from "../../reducers/detailsTab";
import CommonService from "../../services/CommonService";
import "./BrandDetails.css";

export default function BrandDetails(props) {
  const dispatch = useDispatch();

  const commonService = new CommonService();

  const detailsTabSelector = useSelector((state) => state.detailsTab.value);
  const brandsListSelector = useSelector((state) => state.brandsList.value);
  const [fileErrors, setFileErrors] = useState([]);
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const tempImage = useRef(detailsTabSelector.logo);

  const handleChange = (value, inField) => {
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    tempFields[inField] = value;
    dispatch(formDetailsTab(tempFields));
  };

  const handleFileChange = async (file) => {
    const fileTypes = ["image/png", "image/jpg", "image/jpeg"];
    let imageResolution = false;
    const isExist = fileTypes.includes(file && file.type);
    const fileSize = file && file.size / (1024 * 1024);
    const tempFields = commonService.getDeepCopy(detailsTabSelector);
    if (isExist) {
      imageResolution = await commonService.getImageResolution(file);
    }
    setFileErrors([]);
    if (
      file &&
      isExist &&
      fileSize > 0 &&
      fileSize <= 5 &&
      imageResolution.height >= 320 &&
      imageResolution.width >= 320
    ) {
      tempImage.current = await commonService.getBase64(file);
      closeSnackBar();
    } else {
      const errors = commonService.validateFile(
        isExist,
        fileSize,
        imageResolution
      );
      tempImage.current = "";

      setSnackBarState({
        ...snackBarState,
        message: errors.join("\n"),
        open: true,
      });
      setFileErrors([...errors]);
    }
    tempFields["logo"] = tempImage.current;
    tempFields["logo_changed"] = true;
    dispatch(formDetailsTab(tempFields));
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      message: "",
    });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarState.vertical,
          horizontal: snackBarState.horizontal,
        }}
        open={snackBarState.open}
        key={snackBarState.vertical + snackBarState.horizontal}
      >
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: "100%" }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <Box sx={{ textAlign: "-webkit-center", marginBottom: "5rem" }}>
        <div className="edit-profile-logo-container" style={{ width: 200 }}>
          {tempImage.current ? (
            <>
              <img
                src={tempImage.current}
                alt="Logo"
                width="100%"
                height="100%"
              ></img>
            </>
          ) : (
            <>
              {detailsTabSelector && detailsTabSelector.logo ? (
                <img
                  src={detailsTabSelector.logo}
                  alt="Profile Logo"
                  width="10%"
                  height="10%"
                />
              ) : (
                <PersonIcon
                  className="edit-profile-icon"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              )}
            </>
          )}
        </div>
        <div className="edit-btn-container">
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => handleFileChange(e.target.files[0])}
            />

            <Button variant="contained" size="small" component="span">
              Edit Logo
            </Button>
          </label>
        </div>
        {brandsListSelector.viewType === "edit" && (
          <p>
            Brand satus:{" "}
            <span
              style={{
                color: detailsTabSelector.is_active ? "green" : "red",
                fontWeight: "600",
              }}
            >
              {detailsTabSelector.is_active === 1 ? "Active" : "Inactive"}
            </span>
          </p>
        )}
      </Box>
      <Grid container>
        <Grid item xs={6} sx={{ paddingRight: "5px" }}>
          <Box className="mb-3">
            <Typography component={"div"} className="mb-1">
              Brand Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              size="small"
              required
              variant="outlined"
              error={props.errors.name}
              helperText={props.errors.name && "This field is required"}
              value={detailsTabSelector.name || ""}
              onChange={(e) => handleChange(e.target.value, "name")}
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />
          </Box>
          <Box className="mb-3">
            <Typography component={"div"} className="mb-1">
              Brand Reference ID
            </Typography>
            <TextField
              inputProps={{ maxLength: 5 }}
              size="small"
              required
              variant="outlined"
              value={detailsTabSelector.integration_number || ""}
              onChange={(e) =>
                handleChange(e.target.value, "integration_number")
              }
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ paddingLeft: "5px" }}>
          <Box className="mb-3">
            <Typography component={"div"} className="mb-1">
              Account <span style={{ color: "red" }}>*</span>
            </Typography>
            <AccountSelector
              error={props.errors.account}
              isLoading={props.isLoading === "true" ? true : false}
              onAccountSelection={(_, value) =>
                handleChange(value, "client_id")
              }
              accountsList={brandsListSelector.accountsList}
              value={detailsTabSelector.client_id || null}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
