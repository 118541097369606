import { useDispatch, useSelector } from "react-redux";
import CommonService from "./CommonService";
import { formDetailsTab } from "../reducers/detailsTab";
import { Tooltip } from "@mui/material";

class BrandService {
  commonService = new CommonService();

  dispatch = useDispatch();

  brandsListSelector = useSelector((state) => state.brandsList.value);
  detailsTabSelector = useSelector((state) => state.detailsTab.value);
  userSelector = useSelector((state) => state.userInfo.value);

  prepareVariablesToGetBrands = (
    selectedAccount,
    currentPage,
    selectedFilters
  ) => {
    return {
      client_id: selectedAccount.current.id,
      page_no: currentPage.current + 1,
      status:
        selectedFilters.current.brand_status &&
        selectedFilters.current.brand_status.length == 2
          ? null
          : selectedFilters.current.brand_status &&
            selectedFilters.current.brand_status.length == 1
          ? selectedFilters.current.brand_status.map((el) => el.id).join()
          : null,
      pois: selectedFilters.current.assigned_pois
        ? selectedFilters.current.assigned_pois.map((el) => el.id).join()
        : "",
      name: selectedFilters.current.name ? selectedFilters.current.name : null,
      integration_number: selectedFilters.current.brand_ref
        ? selectedFilters.current.brand_ref
        : "",
    };
  };

  prepareBrandsList = (copyBrandsList, checkedList) => {
    const tempBrandsList = this.commonService.getDeepCopy(copyBrandsList);
    tempBrandsList?.forEach((brand) => {
      brand["checked"] = this.checkForSelectedBrands(checkedList, brand.id);
      if (!brand.name) brand["name"] = "";
      if (brand.is_active) {
        brand["brand_status"] = "Active";
      } else {
        brand["brand_status"] = "Inactive";
      }
      if (brand.pois.length >= 1) {
        brand["assigned_pois"] = brand.pois;
      } else {
        brand["assigned_pois"] = "";
      }
      if (brand.integration_number) {
        brand["brand_ref"] = brand.integration_number;
      } else {
        brand["brand_ref"] = "";
      }
    });
    return tempBrandsList;
  };

  checkForSelectedBrands = (checkedBrands, inId) => {
    let isExist = false;
    isExist = checkedBrands.some((ele) => {
      return ele.id === inId;
    });
    return isExist;
  };

  initializeGetBrand = (inGetBrandData) => {
    const tempData = this.commonService.getDeepCopy(
      inGetBrandData.data.getBrandByID
    );
    tempData["client_id"] = this.brandsListSelector.selectedAccount;
    tempData["brand_name"] = inGetBrandData.data.name;
    tempData["reference_number"] = inGetBrandData.data.integration_number;
    tempData["brand_id"] = inGetBrandData.data.id;
    tempData["show_return_eta"] = tempData.show_return_eta === 1 ? true : false;
    tempData["show_wait_eta"] = tempData.show_wait_eta === 1 ? true : false;
    tempData["show_reach_eta"] = tempData.show_reach_eta === 1 ? true : false;
    tempData["logo"] = tempData.icon;
    tempData["logo_changed"] = false;
    tempData["selectedPois"] = tempData.pois;
    delete tempData.pois;
    delete tempData.icon;
    this.dispatch(formDetailsTab(tempData));
  };

  prepareBrandMutationData = () => {
    const data = {
      name: this.detailsTabSelector.name,
      integrationNumber: this.detailsTabSelector.integration_number || "",
      clientId: Number(this.detailsTabSelector.client_id.id),
      icon:
        this.detailsTabSelector.logo && this.detailsTabSelector.logo_changed
          ? this.detailsTabSelector.logo
          : null,
      show_reach_eta: this.detailsTabSelector.show_reach_eta ? 1 : 0,
      show_return_eta: this.detailsTabSelector.show_return_eta ? 1 : 0,
      show_wait_eta: this.detailsTabSelector.show_wait_eta ? 1 : 0,
      pois: this.detailsTabSelector.selectedPois
        ? this.detailsTabSelector.selectedPois.map((poi) => Number(poi.id))
        : [],
    };
    if (this.brandsListSelector.viewType === "edit") {
      data["brandId"] = Number(this.detailsTabSelector.id);
    }
    return data;
  };

  prepareBrandChangeStatus = (checkedList) => {
    const list = this.commonService.getDeepCopy(checkedList);
    return {
      ids: list.map((brand) => brand.id),
    };
  };

  getUiElement = (column, value, id) => {
    switch (column.id) {
      case "brand_status":
        return <span className="tab-font-wrap">{value}</span>;
      case "assigned_pois":
        return this.getViewMoreUI(value);
      case "brand_ref":
        return <span className="tab-font-wrap">{value}</span>;
      default:
        return "";
    }
  };

  getViewMoreUI = (value) => {
    let jsxElement;
    if (value && value.length) {
      if (value.length > 1) {
        jsxElement = (
          <span className="tab-font-wrap">
            {value[0].name}
            <span style={{ color: "blue", cursor: "pointer" }}>
              <Tooltip
                title={value.slice(1).map((tip) => {
                  return <p key={tip.name}>{tip.name}</p>;
                })}
                arrow
              >
                <span>+{value.length - 1}</span>
              </Tooltip>
            </span>
          </span>
        );
      } else if (value.length === 1) {
        jsxElement = <span>{value[0].name}</span>;
      }
    }

    return jsxElement;
  };
}

export default BrandService;
