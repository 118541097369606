import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query AccountQueryList($userId: Int!) {
    listAccounts(user_id: $userId) {
      id
      name
    }
  }
`;

export const GET_USER = gql`
  query GetUserQuery($userId: ID!) {
    getUserByID(id: $userId) {
      id
      firstName
      lastName
      clientId
      clients
      timezone
      roleType
      permissions
      AccConfig
    }
  }
`;
