import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { Provider } from "react-redux";
import Cookies from "js-cookie";

const apiKey = Cookies.get("symfony");
const uri = process.env.REACT_APP_GRAPHQL_URL;

const client = new ApolloClient({
  fetchOptions: {
    credentials: "include",
  },
  headers: {
    Authorization: apiKey,
  },
  uri: uri,
  cache: new InMemoryCache(),
});

const appStore = configureStore({
  reducer: rootReducer,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <Provider store={appStore}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
