import { useApolloClient } from "@apollo/client";
import CommonService from "./CommonService";
import { GET_POIS } from "../graphql/queries/PoiQuery";
import { GET_BRAND } from "../graphql/queries/BrandsListQuery";

export class PoisService {
  common = new CommonService();

  client = useApolloClient();

  getPoisList = async (inUserId, inPageNo, inName, checkedList) => {
    const tempGetAssignedPois = await this.client.query({
      query: GET_POIS,
      variables: {
        userId: Number(inUserId),
        pageNo: inPageNo + 1,
        name: inName || "",
      },
      fetchPolicy: "cache-first",
    });

    const tempPoisList = this.common.getDeepCopy(
      this.preparePoiList(
        tempGetAssignedPois.data.getUserPois.data,
        checkedList
      )
    );

    return {
      total: tempGetAssignedPois.data.getUserPois.total,
      poisList: tempPoisList,
    };
  };

  getAssignedPoisList = async (
    inBrandId,
    inUserId,
    inPageNo,
    inName,
    checkedList
  ) => {
    const { poisList, total } = this.common.getDeepCopy(
      await this.getPoisList(inUserId, inPageNo, inName, checkedList)
    );
    const tempGetBrand = await this.client.query({
      query: GET_BRAND,
      variables: {
        brandId: Number(inBrandId),
      },
      fetchPolicy: "cache-first",
    });
    const { pois: brandPoisList } = this.common.getDeepCopy(
      tempGetBrand.data.getBrandByID
    );
    poisList.forEach((item) => {
      if (this.isExist(item, brandPoisList)) {
        item["checked"] = true;
      }
    });

    const checkedListTmp = poisList.filter((item) => item.checked);
    const unCheckedListTmp = poisList.filter((item) => !item.checked);

    const result = [...checkedListTmp, ...unCheckedListTmp];

    return {
      poisList: result,
      total,
    };
  };

  isExist = (inPoiItem, inBrandPoisList) => {
    // console.log(inPoiItem, inBrandPoisList);
    const tempArr = inBrandPoisList.filter((val) => val.id === inPoiItem.id);
    if (tempArr.length > 0) return true;
    else return false;
  };

  preparePoiList = (copyPoiList, checkedList) => {
    const tempPoiList = this.common.getDeepCopy(copyPoiList);
    tempPoiList.forEach((poi) => {
      poi["checked"] = this.checkForSelectedPoi(checkedList, poi.id);
      if (!poi.name) {
        poi["name"] = "";
      }
      poi["poi"] = poi.name;
      delete poi.name;
    });

    return tempPoiList;
  };

  checkForSelectedPoi = (checkedBrands, inId) => {
    let isExist = false;
    isExist = checkedBrands.some((ele) => {
      return ele === inId;
    });
    return isExist;
  };
}
