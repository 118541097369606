import { combineReducers } from "@reduxjs/toolkit";
import userInfoReducer from "./user";
import brandsListReducer from "./brandsList";
import formDetailsTabReducer from "./detailsTab";
import assignedPoisReducer from "./assignedPois";

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  brandsList: brandsListReducer,
  detailsTab: formDetailsTabReducer,
  assignedPois: assignedPoisReducer,
});

export default rootReducer;
