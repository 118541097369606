import { gql } from "@apollo/client";

export const GET_BRANDS_LIST = gql`
  query BrandsListQuery(
    $client_id: String!
    $page_no: Int!
    $status: Int
    $pois: String
    $name: String
    $integration_number: String
  ) {
    listBrandsPaginated(
      filters: {
        client_id: $client_id
        page_no: $page_no
        status: $status
        pois: $pois
        name: $name
        integration_number: $integration_number
      }
    ) {
      total
      data {
        id
        name
        is_active
        show_return_eta
        show_reach_eta
        show_wait_eta
        pois {
          id
          name
        }
        integration_number
      }
    }
  }
`;

export const GET_FILTERS = gql`
  query FiltersQuery($clientId: Int!) {
    getBrandsPoisFilter(client_id: $clientId) {
      id
      name
    }
  }
`;

export const GET_BRAND = gql`
  query GetBrand($brandId: Int!) {
    getBrandByID(id: $brandId) {
      id
      name
      client_id
      is_active
      integration_number
      show_return_eta
      show_reach_eta
      show_wait_eta
      pois {
        id
        name
      }
      icon
    }
  }
`;

export const EXPORT_BRANDS = gql`
  query ExportBrand($clientId: String!) {
    exportBrands(filters: { client_id: $clientId })
  }
`;
